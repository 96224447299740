
// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    language: 'SK',
    title: 'Marvi Stoner',
    title1: 'Marvi',
    title2: 'Stoner',
    slogan_1: 'Realizácie',
    slogan_2: 'z prírodného kameňa',
    slogan_banner: 'Spojte silu prírody s našou kreativitou',
    slogan_4: 'Spojte sa s prírodou, obklopte sa kameňom s Marvi Stoner',
    intro: 'Vitajte na online expozícii našich realizácií kamenných okladov, dlažieb a murív - mieste, kde sa stretáva tradičná elegancia s modernou estetikou. Kamenné oklady sa stali nenahraditeľným prvkom v architektonickom dizajne, zdobí domy, verejné budovy a komerčné priestory po celom svete.',
    intro_text: 'V našej ponuke nájdete široký výber kamenárskych prác',
    intro_text_2: 'Kamenné obklady a dlažby vytvárajú unikátny a nadčasový vzhľad. Vyrobené z mramoru, žuly, vápenca či bridlice, sú mimoriadne odolné a trvanlivé, čo z nich robí ideálny materiál pre interiéry aj exteriéry.',
    company_info: 'Informácie o spoločnosti Marvi Stoner',
    page_intro: 'Realizácia kamenných obkladov, dlažieb a murív',

    // ============================================================================================================
    // MAIN PAGE - TEXTY
    // ============================================================================================================    
    obklady: 'Obklady',
    obklady_note: 'rôzne druhy kamenných obkladov, od klasických vápencov až po luxusné mramory a mozaiky',

    obklady_intro_text: 'S kamennými obkladmi môžete transformovať každý priestor na miesto plné osobitosti a pôvabu.',
    obklady_intro_sub_text: 'Bez ohľadu na to, či plánujete renovovať váš domov, pridať šmrnc vášmu obchodnému priestoru alebo vytvoriť elegantný exteriér vášho domu, kamenné obklady sú ideálnym riešením pre dosiahnutie štýlového a trvanlivého povrchu.',
    obklady_variant_1: 'Interiérové obklady',
    obklady_variant_1_text: 'Používané v obytných priestoroch, kúpeľniach, kuchyniach, obývačkách.',
    obklady_variant_2: 'Exteriérové obklady',
    obklady_variant_2_text: 'Odolné voči poveternostným vplyvom, vhodné pre fasády, záhradné múry, ploty.',
    obklady_variant_3: 'Dekoratívne obklady',
    obklady_variant_3_text: 'Používané na vytvorenie umeleckých prvkov, mozaiky, steny s výrazným vizuálnym efektom.',

    // ----------------------------------------------------------------------------------------------------------------

    dlazby: 'Dlažby',
    dlazby_note: 'dlažby od tradičných keramických až po moderné betónové a prírodné kameňové dlažby',

    dlazby_intro_text: 'Dlažby predstavujú základný stavebný prvok pre každý interiér či exteriér, a naša ponuka vám poskytuje široký výber možností.',
    dlazby_intro_sub_text: 'Náš sortiment zahŕňa rôzne druhy dlažieb, od tradičných až po moderné prírodné kameňové dlažby. Sme presvedčení, že každá dlažba má svoje jedinečné vlastnosti a špecifické využitie, a preto vám radi poskytneme odborné poradenstvo a pomôžeme vám vybrať tú správnu dlažbu pre vaše projekty.',
    dlazby_variant_1: 'Formatovaná dlažba',
    dlazby_variant_1_text: 'Vytvoreného z prírodného kameňa, ktorý je narezaný do pravidelných tvarov a veľkostí',
    dlazby_variant_2: 'Neformátovaná dlažby',
    dlazby_variant_2_text: 'Dlažba, ktorá sa ponecháva v nepravidelných tvaroch a veľkostiach',
    dlazby_variant_3: 'Dlažobné kocky',
    dlazby_variant_3_text: 'Malé bloky kameňa používané na vytváranie pevných a trvanlivých povrchov, ako sú chodníky, príjazdové cesty, námestia alebo historické ulice',

    // ----------------------------------------------------------------------------------------------------------------

    muriva: 'Murivá',
    muriva_note: 'stavebné konštrukcie vytvorené z kameňov, uložené a spojené nasucho, alebo pomocou malty',

    muriva_intro_text: 'Kamenné murivá, tradičný stavebný prvok používaný po stáročia, sú obdivuhodným spojením estetiky, funkčnosti a trvanlivosti.',
    muriva_intro_sub_text: 'Tieto múry sa často nachádzajú v historických stavbách, záhradách, viniciach či pri budovaní terás a plotov. Kamenné murivá nielenže dodávajú prostrediu rustikálny a autentický vzhľad, ale zároveň poskytujú vynikajúcu odolnosť voči poveternostným vplyvom a dlhovekosť. ',
    muriva_variant_1: 'Nosné murivo',
    muriva_variant_1_text: 'Používané v nosných konštrukciách budov, kde je dôležitá vysoká pevnosť a stabilita.',
    muriva_variant_2: 'Dekoratívne murivo',
    muriva_variant_2_text: 'Používané na ozdobné účely, ako sú fasády, záhradné múry, a architektonické detaily.',
    muriva_variant_3: 'Oplotenie',
    muriva_variant_3_text: 'Murivo používané na výstavbu plotov a oplotení okolo pozemkov a budov.',
    muriva_variant_4: 'Záhradné murivo',
    muriva_variant_4_text: 'Murivo používané v záhradných konštrukciách, ako sú oporné múry, terasy a okrasné prvky.',
    muriva_variant_footer_text: 'Kamenné murivá nielenže dodávajú prostrediu rustikálny a autentický vzhľad, ale zároveň poskytujú vynikajúcu odolnosť voči poveternostným vplyvom a dlhovekosť. Sú symbolom ľudskej zručnosti a harmonického prepojenia s prírodou.',

    // ----------------------------------------------------------------------------------------------------------------

    others: 'Ostatné',
    others_note: 'realizácie, ktoré nezapadajú do žiadnych konkrétnych kategórií',

    // ----------------------------------------------------------------------------------------------------------------

    badroom: 'Obklady kúpeľní',
    badroom_note: 'luxusný a nadčasový prvok, ktorý dodáva priestoru elegantný a prírodný vzhľad',

    bathroom_intro_text: 'Kamenné obklady kúpeľní predstavujú luxusný a nadčasový prvok, ktorý dodáva priestoru elegantný a prírodný vzhľad. ',
    bathroom_intro_sub_text: 'Prinášame vám široký výber kamenných obkladov, ktoré dotvárajú luxusný a prírodný vzhľad vašich kúpeľní.',

    bathroom_variant_1: 'Stenové obklady',
    bathroom_variant_1_text: 'Kamenné obklady používané na steny kúpeľne, ktoré dodávajú priestorom eleganciu a trvácnosť.',
    bathroom_variant_2: 'Podlahové obklady',
    bathroom_variant_2_text: 'Odolné kamenné dlaždice vhodné pre kúpeľňové podlahy, ktoré odolávajú vlhkosti a opotrebeniu.',
    bathroom_variant_3: 'Dekoratívne prvky',
    bathroom_variant_3_text: 'Špeciálne upravené kamenné prvky, ako sú hranaté lišty, bordúry alebo obkladové detaily, ktoré pridávajú kúpeľni špeciálny vzhľad.',
    bathroom_variant_footer_text: 'Nech už preferujete moderný minimalistický štýl alebo tradičnú eleganciu, naše kamenné obklady poskytnú vašej kúpeľni jedinečný a osobitný charakter. Prezkúmajte našu kolekciu a začnite plánovať transformáciu vašej kúpeľne ešte dnes!',

    // ----------------------------------------------------------------------------------------------------------------

    gallery: 'Galéria',
    gallery_text: 'Vitajte v našej galérii - realizácií naších prác kamenných obkladov, dlažieb a murív.',
    gallery_categories: 'Ďalšie kategórie realizácii',

    stone_gallery: 'Galéria našich realizácií',
    stone_gallery_note: 'Na tejto stránke nájdete výber našich najlepších projektov, ktoré demonštrujú krásu, eleganciu a trvácnosť prírodného kameňa.',
    stone_gallery_text: 'Veríme, že vás naše práce inšpirujú a že ste nájdete množstvo nápadov pre váš nasledujúci projekt. Ak máte akékoľvek otázky, alebo by ste sa chceli dozvedieť viac o našich službách - kontaktujte nás.',

    stone_gallery_obklady: 'Kamenné obklady',
    stone_gallery_obklady_text: 'Nechajte sa inšpirovať našimi realizáciami.',

    stone_gallery_dlažby: 'Kamenné dlažby',
    stone_gallery_dlažby_text: 'galérii realizácií kamenných obkladov',

    stone_gallery_murivá: 'Kamenné murivá',
    stone_gallery_murivá_text: 'galérii realizácií kamenných obkladov',

    stone_gallery_ostatne: 'Iné realizácie',
    stone_gallery_ostatne_text: 'galérii realizácií kamenných obkladov',

    // ============================================================================================================
    // VYHODY KAMENNYCH OBKLADOV
    // ============================================================================================================
    stone_advantage: 'Výhody použitia kameňa',
    stone_advantage_text: 'Kamenné obklady a dlažby majú množstvo výhod, ktoré z nich robia atraktívnu voľbu pre rôzne priestory',

    stone_advantage_1: 'Odolnosť a trvanlivosť',
    stone_advantage_text_1: 'Kamenné materiály ako mramor, žula a vápenec sú mimoriadne pevné a odolné voči opotrebeniu, čo zaručuje ich dlhú životnosť.',

    stone_advantage_2: 'Estetická hodnota',
    stone_advantage_text_2: 'Prírodný kameň prináša do priestoru elegantný a nadčasový vzhľad, ktorý sa hodí do rôznych dizajnových štýlov',

    stone_advantage_3: 'Jednoduchá údržba',
    stone_advantage_text_3: 'Kamenné obklady a dlažby sa ľahko čistia a udržiavajú, čo z nich robí praktickú voľbu pre rušné domácnosti.',

    stone_advantage_4: 'Ekologická voľba',
    stone_advantage_text_4: 'Prírodný kameň je ekologický materiál, ktorý nevyžaduje veľa spracovania, a je recyklovateľný.',


    // ============================================================================================================
    // PRODUKTY
    // ============================================================================================================
    stone_products: 'Produkty',
    stone_products_text: 'Naša ponuka zahŕňa široký výber kamenných materiálov - od tradičných vápencov a mramoru až po moderné mozaiky a betónové dlaždice. Sme tu, aby sme vám pomohli vybrať ten správny materiál, ktorý zvýrazní štýl vášho interiéru alebo exteriéru a zároveň bude odolný a ľahko udržateľný.',
    stone_products_obklady_dlazby: 'Obklady a dlažby',
    stone_products_obklady_dlazby_text: 'Sme špecialisti na prírodný kameň a ponúkame široký sortiment produktov, ktoré dodajú vášmu domovu, záhrade alebo komerčnému priestoru nezameniteľný štýl a eleganciu.',
    stone_products_text2: 'V našej ponuke kamenných obkladov a dlažieb nájdete široký výber materiálov, ktoré uspokoja aj tie najnáročnejšie požiadavky na dizajn a funkčnosť. Kontaktujte nás ešte dnes a začnite svoju cestu k úžasným kamenným povrchom!',
    stone_products_offer: 'Široká ponuka',

    // ============================================================================================================
    // O NÁS
    // ============================================================================================================
    about_us: 'O nás',
    about_us_label: 'Radi by sme sa vám predstavili!',
    about_us_sub_label: 'Naša spoločnosť sa zaoberá profesionálnou realizáciou s prírodného kameňa, pričom združujeme roky skúseností s vášňou pre detail a kvalitu.',
    about_us_text_1: 'V našom svete sa snúbi vášeň s odbornou zručnosťou, pričom každý kameň rozpráva svoj jedinečný príbeh o kvalite a elegancii. S 25-ročnými skúsenosťami sme hrdí na to, že sme lídrom v oblasti realizácii z prírodného kameňa, ktoré dotvárajú interiéry a exteriéry s osobitým štýlom a charakterom.',
    about_us_text_2: 'Naša spoločnosť sa vyvinula z hľadania dokonalosti v oblasti prírodného kameňa. Od svojich skromných začiatkov sme sa stali renomovaným partnerom pre tých, ktorí hľadajú jedinečné riešenia pre svoje projekty. Sme zaviazaní k poskytovaniu najvyššej kvality výrobkov a služieb, ktoré presahujú očakávania našich zákazníkov.',
    about_us_text_3: 'Každý člen nášho tímu je špecialista vo svojej oblasti a zdieľa vášeň a oddanosť k našej spoločnej vízii. Sme tu, aby sme vám poskytli odborné poradenstvo, kreatívne riešenia a profesionálnu podporu počas celej cesty vášho projektu.',

    // ============================================================================================================
    // KONTAKTNE INFORMACIE
    // ============================================================================================================
    company_name: 'Obchodný názov',
    company_street: 'Ulica',
    company_town: 'Ulica',
    company_psc: 'PSČ',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Štát',
    logo: 'Logo',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',
    call_us: 'Volajte',
    contact_form: 'Kontaktný formulár',
    contact_form_text: 'Vyplňte náš jednoduchý kontaktný formulár a my sa Vám čoskoro ozveme.',
    message_question: 'Chcete sa na niečo opýtať? Napíšte nám.',
    message_text: 'Text správy',
    message_email: 'Vaša emailová adresa',
    message_mobil: 'Vaše telefónne číslo',
    message_send_ok: 'Správa bola úspešne odoslaná',
    message_send_err: 'Pro odosielaní nastala chyba. Skúste požiadavku opakovať.',
    slovakia: 'Slovensko',

    // ============================================================================================================
    // OTHERS
    // ============================================================================================================
    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',
    required: 'Údaje označené červenou sú povinné',
    number: 'Číslo',
    active: 'Aktívny',
    label: 'Názov',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',
    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_request: 'Odoslať požiadavku',
    saving: '...',
    subscribe: 'Odoberať',
    error: 'Chyba',
    goto_app: 'Prejsť do aplikácie',
    demo: 'Vyskúšať zadarmo',
    captcha_error: 'Chýba potvrdenie - nie som robot',
    send_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',
    back_to_home: 'Späť na hlavnú stránku',
    order: 'Objednávka',
    order_text: 'Zakúpenie licencie',
    home: 'Domov',
    menu: 'Menu',
    required_data: 'Údaje označené * sú povinné',
    watch: 'Pozrieť',


    // ============================================================================================================
    // GDPR + COOKIES + VOP
    // ============================================================================================================
    gdpr: 'Ochrana osobných údajov',
    cookies_rules: 'Zásady používania súborov cookies',
    sell_docs: 'Všeobecné obchodné podmienky',


    // ============================================================================================================
    // MENU
    // ============================================================================================================
    products: 'Produkty',
    products_text: 'Výroba a predaj rohoží',
    product_obklady: 'Kamenné obklady',
    product_dlažby: 'Kamenné dlažby',
    product_muriva: 'Kamenné murivá',
    reference: 'Referencie',
    reference_text: 'Naši klienti',
    contact: 'Kontakt',
    contact_text: 'Kontaktujte nás',
    contact_data: 'Kontaktné údaje',
    services: 'Služby',
    our_services: 'Čo ponúkame',
    more_info: 'Viac informácii',
    write: 'Napište nám',
    contact_us: 'Kontaktujte nás',
    contact_us_text: 'Ak vás naše práce oslovili a mali by ste záujem o realizáciu, alebo o spoluprácu, neváhajte nás kontaktovať.',
    contact_us_text_2: 'Ak máte akékoľvek otázky, pripomienky alebo jednoducho len chcete povedať „ahoj“, neváhajte nás kontaktovať.',


    // ============================================================================================================
    // FOOTER
    // ============================================================================================================
    copyright: 'Copyright',
    footer_text: 'Nech už hľadáte klasický elegantný vzhľad alebo moderný dizajn, sme tu, aby sme vám pomohli nájsť to pravé riešenie pre vaše projekty. Náš tím odborníkov vám rád poskytne odborné poradenstvo a pomoc pri výbere najvhodnejších kamenných okladov, alebo dlažieb.',

}

