import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerLight, ContainerCompare, Line, GoHome, GetPages } from './items.js';
import { faImages, faPhone } from '@fortawesome/free-solid-svg-icons';


export const Gallery = (props) => {

    // IMAGES
    const image = require('./react/app/gallery_2.jpg');
    const image_contact_us = require('./react/app/contact_us_1.jpg');
    const image_obklady = require('./react/app/image_obklady_2.jpg');
    const image_dlazby = require('./react/app/image_dlazby_2.jpg');
    const image_muriva = require('./react/app/image_muriva_2.jpg');
    const image_ostatne = require('./react/app/image_ostatne.jpg');
    const image_video = require('./react/app/gallery_video.jpg');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [play, setPlay] = useState(false);

    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 2); // ID STRANKY -> prepnutie menu
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);


        return () => {
        };

    }, [props.theme, props.language, props.company]);



    return (
        <div style={{ ...styles.BlockCenter }}>


            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.white }}>
                <Space />
                <ContainerLight noButton icon={faImages} label={lang.gallery} text={lang.gallery_text} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 99)} />
                <Space />
                <ContainerText center sub_text={lang.stone_gallery_text} theme={theme} lang={lang} language={language} />
                <Space height={40} />
                {play == false ?
                    <img onClick={() => setPlay(true)} src={image_video} style={{ width: '100%', height: '100%', maxWidth: isSmall ? width - 40 : 640, maxHeight: 360, objectFit: 'contain', borderRadius: 20, cursor: 'pointer' }}></img>
                    :
                    <video id='ending-video' autoPlay playsInline controls style={{ maxWidth: isSmall ? width - 40 : 640, maxHeight: 360, borderRadius: 20 }}>
                        <source src={global.web + '/public/uploads/video/promo.mp4'} type="video/mp4" />
                    </video>
                }
                <Space />
                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                    <ContainerBox label={lang.obklady} text={lang.obklady_note} button={lang.watch} theme={theme} lang={lang} language={language} image={image_obklady} func={() => func(1, 30)} />
                    <ContainerBox label={lang.dlazby} text={lang.dlazby_note} button={lang.watch} theme={theme} lang={lang} language={language} image={image_dlazby} func={() => func(1, 31)} />
                    <ContainerBox label={lang.muriva} text={lang.muriva_note} button={lang.watch} theme={theme} lang={lang} language={language} image={image_muriva} func={() => func(1, 32)} />
                    <ContainerBox label={lang.others} text={lang.others_note} button={lang.watch} theme={theme} lang={lang} language={language} image={image_ostatne} func={() => func(1, 33)} />
                </div>
                <Space height={90} />
            </div>

            <ContainerDark icon={faPhone} label={lang.contact_us} text={lang.contact_us_text} theme={theme} lang={lang} language={language} image={image_contact_us} func={() => func(1, 3)} />

        </div>
    )
}
