import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Dialog, IconButton, Pagination, useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { ContainerDark, Space, ContainerLight, GoHome, ContainerPhoto, GetPages, ContainerBox, ContainerText } from './items.js';
import { faAngleLeft, faAngleRight, faGripVertical, faInfo, faInfoCircle, faP, faPhone, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const GalleryPhotos = (props) => {

    const category_id = props.category_id;

    // IMAGES
    const background = require('./react/app/background_gallery.jpg');
    const image = require('./react/app/gallery_obklady.jpg');
    const image_gallery = require('./react/app/gallery_1.jpg');

    const image_obklady = require('./react/app/image_obklady_2.jpg');
    const image_dlazby = require('./react/app/image_dlazby_2.jpg');
    const image_muriva = require('./react/app/image_muriva_2.jpg');
    const image_ostatne = require('./react/app/image_ostatne.jpg');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    const buttonSize = 60;

    let { func } = props;
    var running = false;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 99); // ID STRANKY -> prepnutie menu
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------

        if (running == false) {
            lang = GetLang(props.language);
            color = global.themes[props.theme];

            setTheme(props.theme);
            setLanguage(props.language);

            db_get(0, false);

            running = true;
        }

        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            Debug('RESIZE');
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [props.theme, props.language, props.company, props.category_id]);

    /* *******************************************************************************
            Nacítanie fotografii
        ******************************************************************************* */
    const db_get = async (page, scroll) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'gallery', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    category: category_id,
                    start: page * global.items_max,
                    length: global.items_max,
                    filter: false
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));
                if (scroll == true) {
                    GoToStart();
                } else {
                    GoHome();
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Prev = () => {
        var index = selectedIndex - 1;
        if (index >= 0) {
            setSelectedIndex(index);
            setItemSelected(items[index]);
        }
    }

    const Next = () => {
        var index = selectedIndex + 1;
        if (index < items.length) {
            setSelectedIndex(index);
            setItemSelected(items[index]);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, true);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_gallery_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    return (
        <div style={{ ...styles.BlockCenter }}>


            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.white }}>
                <Space />
                <ContainerLight noButton icon={faGripVertical} label={props.title} text={lang.stone_gallery_obklady_text} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 10)} />

                <div id='id_gallery_start' style={{ ...styles.Block }}></div>
                <Space />

                <ContainerText center label={lang.stone_gallery} theme={theme} lang={lang} language={language} />

                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                    {items != false ? items.map((item, index) => (
                        <ContainerPhoto key={item.id} item={{ id: item.id }} theme={theme} lang={lang} language={language} image={global.web + '/' + item.thumb} func={() => { setSelectedIndex(index); setItemSelected(item) }} />
                    )) : null}

                    {pages > 1 ?
                        <div style={{ ...styles.Block, marginTop: 40 }}>
                            <Pagination count={pages} size={'large'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                        </div>
                        : null}

                </div>
                <Space height={90} />
            </div>

            <ContainerText center text={lang.gallery_categories} theme={theme} lang={lang} language={language} />
            <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                {props.category_id == global.gallery.obklady ? null :
                    <ContainerBox small label={lang.obklady} button={lang.watch} theme={theme} lang={lang} language={language} image={image_obklady} func={() => func(1, 30)} />
                }
                {props.category_id == global.gallery.dlazby ? null :
                    <ContainerBox small label={lang.dlazby} button={lang.watch} theme={theme} lang={lang} language={language} image={image_dlazby} func={() => func(1, 31)} />
                }
                {props.category_id == global.gallery.muriva ? null :
                    <ContainerBox small label={lang.muriva} button={lang.watch} theme={theme} lang={lang} language={language} image={image_muriva} func={() => func(1, 32)} />
                }
                {props.category_id == global.gallery.ostatne ? null :
                    <ContainerBox small label={lang.others} button={lang.watch} theme={theme} lang={lang} language={language} image={image_ostatne} func={() => func(1, 33)} />
                }
            </div>

            <Space height={90} />

            <ContainerDark icon={faPhone} label={lang.contact_us} text={lang.contact_us_text} theme={theme} lang={lang} language={language} image={image_gallery} func={() => func(1, 3)} />

            {itemSelected != false ?
                <Dialog fullScreen open={true}>
                    <div style={{ ...styles.Block, background: `url(${background})`, backgroundSize: 'cover', width: width, height: height, position: 'relative' }}>

                        <img src={global.web + '/' + itemSelected.image} style={{ width: '100%', height: '100%', maxWidth: width, maxHeight: height, objectFit: 'contain' }}></img>

                        <IconButton onClick={() => setItemSelected(false)} style={{ ...styles.ButtonIcon, position: 'absolute', top: 20, right: 20, backgroundColor: '#FFFFFF' }}>
                            <FontAwesomeIcon style={{ width: 12, color: color.black }} icon={faXmark} />
                        </IconButton>

                        {items != false ? selectedIndex > 0 ?
                            <div onClick={() => Prev()} style={{ ...styles.Block, width: buttonSize, height: buttonSize, backgroundColor: '#00000040', position: 'absolute', top: (height / 2) - (80 / 2), left: 20, borderRadius: 10, cursor: 'pointer' }}>
                                <FontAwesomeIcon style={{ height: buttonSize - 4, color: '#FFFFFF50' }} icon={faAngleLeft} />
                            </div>
                            : null : null}

                        {items != false ? selectedIndex < items.length - 1 ?
                            <div onClick={() => Next()} style={{ ...styles.Block, width: buttonSize, height: buttonSize, backgroundColor: '#00000040', position: 'absolute', top: (height / 2) - (80 / 2), right: 20, borderRadius: 10, cursor: 'pointer' }}>
                                <FontAwesomeIcon style={{ height: buttonSize - 4, color: '#FFFFFF50' }} icon={faAngleRight} />
                            </div>
                            : null : null}
                    </div>
                </Dialog>
                : null}

        </div >
    )
}
