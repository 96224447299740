import React, { useEffect, useState, useRef, useCallback } from 'react';
import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Main } from './main';
import { MenuTop, Menu, Footer } from './items';
import { Debug, GetLang, LoadLanguage, LoadTheme, SaveLanguage } from './functions';
import { styles } from './styles.js';
import { useMediaQuery } from '@mui/material';
import { Products } from './products.js';
import { Gallery } from './gallery.js';
import { Contact } from './contact.js';
import { ProductsDlazby } from './products_dlazby.js';
import { ProductsObklady } from './products_obklady.js';
import { ProductsMuriva } from './products_muriva.js';
import { ProductsKupelne } from './products_kupelne.js';
import { AboutUs } from './about_us.js';
import { GalleryPhotos } from './gallery_photos.js';

function App() {
  const navigate = useNavigate();

  const loadingScreen = document.getElementById('loading-screen');

  const [theme, setTheme] = useState(LoadTheme());
  const [language, setLanguage] = useState(LoadLanguage());
  const [menuID, setMenuID] = useState(0);
  const [company, setCompany] = useState(false);

  // SCREEN SIZES
  const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
  const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
  const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

  // SCREEN SETTINGS
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  var color = global.themes[theme];
  var lang = GetLang(language);

  var running = false;

  useEffect(() => {

    if (running == false) {
      db_get();

      running = true;
    }

    if (loadingScreen) {
      loadingScreen.style.display = 'none';
    }

    function handleResize() {
      setWidth(window.innerWidth);
      //setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* *******************************************************************************
      NAČÍTANIE UDAJOV O SPOLOCNOSTI
  ******************************************************************************* */
  const db_get = async () => {

    try {
      const response = await fetch(
        global.db_url + 'company', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: 1
        })
      })

      const json = await response.json();
      Debug(json);
      if (json.ok > 0) {
        setCompany(json.company);
      }

    } catch (error) {
      console.log(error);
    }
  }


  const MenuTopPress = (typ, value) => {
    if (typ == 0) {
      // ZMENA JAZYKA
      setLanguage(value);
      SaveLanguage(value);
    }
    if (typ == 1) {
      // FAREBNEJ TEMY
      setTheme(value);
    }

  }

  const MenuPress = (typ, value) => {
    Debug(typ);
    if (typ == 1) {
      // MENU PRESS
      ChangeLink(value);
    }
    if (typ == 2) {
      // ZMENA JAZYKA
      setLanguage(value);
      SaveLanguage(value);
    }
    if (typ == 3) {
      // FAREBNEJ TEMY
      setTheme(value);
    }
  }

  const FooterPress = (typ, id) => {
    // BOL STLAČENÝ LINK      
    if (typ == 0) {
      ChangeLink(id);
    }
  }

  const ChangeLink = (id) => {
    if (id == 0) {
      // HOME
      navigate('/');
    }
    if (id == 1) {
      // produkty
      navigate('/products');
    }
    if (id == 2) {
      // galéria
      navigate('/gallery');
    }
    if (id == 3) {
      // kontakt
      navigate('/contact');
    }
    if (id == 10) {
      // produkty - obkladacky
      navigate('/products_o');
    }
    if (id == 11) {
      // produkty - dlazby
      navigate('/products_d');
    }
    if (id == 12) {
      // produkty - muriva
      navigate('/products_m');
    }
    if (id == 13) {
      // produkty - kupelne
      navigate('/products_k');
    }
    if (id == 20) {
      // o nás
      navigate('/about_us');
    }
    if (id == 30) {
      // galéria - oklady
      navigate('/gallery_o');
    }
    if (id == 31) {
      // galéria - dlažby
      navigate('/gallery_d');
    }
    if (id == 32) {
      // galéria - murivá
      navigate('/gallery_m');
    }
    if (id == 33) {
      // galéria - ostatné
      navigate('/gallery_i');
    }

  }

  const PageResult = (typ, id) => {
    if (typ == 0) {
      // KAZDA STRANKA PO NAČITANÍ VRATI SVOJ INDEX -> PREPNUTIE MENU
      // 99 => NONE
      setMenuID(id);
    }
    if (typ == 1) {
      // Stlačený link
      ChangeLink(id);
    }
  }

  return (
    <div className="App" style={{ ...styles.BlockCenter, backgroundColor: color.white, WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />

      <Helmet>
        <title>{global.title}</title>
        <meta name="description" content="Marvi Stoner - kamenné obklady a dlažby" />
      </Helmet>

      <div style={{ ...styles.BlockCenter }}>

        {isSmall ? null :
          <MenuTop company={company} isSmall={isSmall} theme={theme} lang={lang} language={language} func={MenuTopPress.bind(this)} />
        }

        <Menu id={menuID} company={company} isSmall={isSmall} theme={theme} lang={lang} language={language} func={MenuPress.bind(this)} />

        <Routes>
          <Route path="/" element={<Main company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/products" element={<Products company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/products_d" element={<ProductsDlazby company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/products_o" element={<ProductsObklady company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/products_m" element={<ProductsMuriva company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/products_k" element={<ProductsKupelne company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/gallery" element={<Gallery company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/gallery_d" element={<GalleryPhotos category_id={global.gallery.dlazby} title={lang.stone_gallery_dlažby} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/gallery_o" element={<GalleryPhotos category_id={global.gallery.obklady} title={lang.stone_gallery_obklady} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/gallery_m" element={<GalleryPhotos category_id={global.gallery.muriva} title={lang.stone_gallery_murivá} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/gallery_i" element={<GalleryPhotos category_id={global.gallery.ostatne} title={lang.stone_gallery_ostatne} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/contact" element={<Contact company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/about_us" element={<AboutUs company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          {/*
                <Route path="/product/:id" element={<Product theme={theme} lang={lang} language={language} func={PageResult.bind(this)}/>} />
          */}
          <Route path="*" element={<Main company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
        </Routes>

        <Footer company={company} theme={theme} lang={lang} language={language} func={FooterPress.bind(this)} />

      </div >
    </div >
  );
}

export default App;
