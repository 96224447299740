import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { LoadLanguage } from './functions.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerLight, ContainerCompare, Line, GoHome, ContainerContact, ContainerContacForm } from './items.js';
import { faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';


export const Contact = (props) => {

    // IMAGES
    const image = require('./react/app/about_us_2.jpg');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState('');
    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;
    var running = false;

    useEffect(() => {

        if (running == false) {
            // ---------------------------------------------------------------------------
            func(0, 3); // ID STRANKY -> prepnutie menu
            GoHome(); // Scroll na začiatok stránky po načítaní
            // ---------------------------------------------------------------------------

            lang = GetLang(props.language);
            color = global.themes[props.theme];

            setTheme(props.theme);
            setLanguage(props.language);

            running = true;
        }

        return () => {
        };

    }, [props.theme, props.language, props.company]);


    return (
        <div style={{ ...styles.BlockCenter }}>


            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.white }}>
                <Space />
                <ContainerBasic noButton icon={faPhone} label={lang.contact_text} text={lang.contact_us_text_2} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 99)} />
                <Space />
                <ContainerText center label={lang.contact_data} theme={theme} lang={lang} language={language} />
                <Line color={color.gray} />
                <Space />
                <ContainerContact company={props.company} center theme={theme} lang={lang} language={language} />
                <div style={{ ...styles.Block }}>

                </div>
                <Space height={100} />
            </div>

            <ContainerContacForm icon={faInfoCircle} label={lang.stone_products_obklady_dlazby} text={lang.stone_products_obklady_dlazby_text} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 1)} />

        </div>
    )
}
