import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { sk } from './language_sk.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faShoppingCart, faSearch, faAngleDoubleLeft, faChevronLeft, faAngleDoubleRight, faChevronRight, faBars, faCross, faTimesCircle, faList, faAngleDown, faAnglesUp, faAngleLeft, faGear, faCaretUp, faArrowRight, faAngleRight, faPerson, faUser, faPenToSquare, faClose, faRightFromBracket, faEdit, faArrowRightToBracket, faHome, faPaperPlane, faPhone, faCheck, faXmark, faHeart, faPalette, faP, faImage, faPlay, faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faMobile, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Slide, getCardActionAreaUtilityClass } from '@mui/material';
//import Slider from "react-slick";
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, useMediaQuery } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
/*
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
*/
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Parallax } from 'react-scroll-parallax';
import { Spin as Hamburger } from 'hamburger-react';
import { Debug, GetLang, LoadLanguage, LoadTheme } from './functions.js';
import { GetTodayYear, Today } from './functions_date.js';
import { faPallet, height } from '@fortawesome/free-solid-svg-icons/faPallet';
import ReCAPTCHA from 'react-google-recaptcha';

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }
}

export const Logo = (props) => {

    const logo = require('./react/app/logo.png');
    const logo_white = require('./react/app/logo_white.png');

    const lang = props.lang;
    var color = global.themes[props.theme];

    return (
        <div style={{ ...styles.BlockRowRaw }}>
            <div style={{ ...styles.Block, width: undefined, height: props.height }}>
                <img src={props.white == true ? logo_white : logo} style={{ width: '100%', height: '100%', maxWidth: props.height - 16, maxHeight: props.height - 16, objectFit: 'contain', transitionDuration: '0.5s' }}></img>
            </div>
            <div style={{ ...styles.Block, width: undefined, height: props.height, marginLeft: 10 }}>
                <p style={{ ...styles.TextXLarge, color: color.white }}><b>{lang.title1.toUpperCase()}</b><span style={{ marginLeft: 12, letterSpacing: 2 }}>{lang.title2}</span></p>
            </div>
        </div>
    )
}

/*
*
* =================================================================================================================
* TOP MENU
* 
* výber jazyka
* 
* =================================================================================================================
*
*/
export const MenuTop = (props) => {

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState(0);

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const navigate = useNavigate();

    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');

    // CONSTANTS
    const menuHeight = global.menu_top_size;
    const iconSize = 30;

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    const disabled = 0.3;

    let { func } = props;

    useEffect(() => {

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language]);

    const ChangeLanguage = (id) => {
        func(0, id);
    }

    const ChangeTheme = (id) => {
        func(1, id);
    }


    return (
        <div style={{ ...styles.Block, height: menuHeight, backgroundColor: color.top_menu_color }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.BlockRow, width: global.max_width_padding }}>
                    <div style={{ ...styles.BlockRowRaw, width: '50%' }}>
                    </div>
                    <div style={{ ...styles.BlockRight, width: '50%' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {global.themes_enabled == true ?
                                <div onClick={() => ChangeTheme(theme == 0 ? 1 : 0)} style={{ ...styles.Block, width: iconSize, height: iconSize, marginRight: 40, background: color.button_palette, borderRadius: iconSize / 2 }}>
                                    <FontAwesomeIcon style={{ height: 22, color: color.white }} icon={faPalette} />
                                </div>
                                : null}
                            <div onClick={() => ChangeLanguage(0)} style={{ width: iconSize, height: iconSize, marginRight: 20 }}>
                                <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 0 ? 1 : disabled }}></img>
                            </div>
                            <div onClick={() => ChangeLanguage(1)} style={{ width: iconSize, height: iconSize }}>
                                <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 1 ? 1 : disabled }}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

/*
*
* =================================================================================================================
* MENU
* 
* hlavné menu
* 
* =================================================================================================================
*
*/
export const Menu = (props) => {


    const logo_marvi = require('./react/app/logo_marvi.png');
    const background = require('./react/app/background_menu.jpg');

    const [isStacked, setStacked] = useState(false);
    const [theme, setTheme] = useState(0);
    const [language, setLanguage] = useState(0);

    // VALUES
    const [index, setIndex] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuStarted, setMenuStarted] = useState(false);

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const navigate = useNavigate();

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    // BUTTONS
    var buttonSize = isSmall ? 80 : isMedium ? 110 : 128;
    var fontSize = isSmall ? 20 : isMedium ? 16 : 20;

    // SMALL MENU
    const menuSmallHeight = 60;
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');
    const disabled = 0.3;

    const iconSize = 30;


    const stickyRef = useRef(null);
    let { func } = props;

    useEffect(() => {

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);
        setIndex(props.id);

        const handleScroll = () => {
            if (stickyRef.current) {
                const stickyTop = stickyRef.current.getBoundingClientRect().top;
                setStacked(stickyTop <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [props.theme, props.language, props.id]);


    const ChangeIndex = (id) => {
        CloseMenu();
        setIndex(id);
        func(1, id);
    }

    const ChangeLanguage = (id) => {
        CloseMenu();
        func(2, id);
    }

    const ChangeTheme = (id) => {
        CloseMenu();
        func(3, id);
    }

    const OpenMenu = () => {
        setMenuOpen(true);
        setTimeout(() => {
            setMenuStarted(true);
        }, 250);
    }


    const CloseMenu = () => {
        setMenuOpen(false);
        setMenuStarted(false);
    }


    const MenuButton = (props) => {

        var buttonHeight = global.menu_size_stacked;// isStacked ? global.menu_size_stacked : global.menu_size_expanded;
        const buttonHeightOffset = 8;
        const radius = 16;

        return (
            <div
                onClick={() => ChangeIndex(props.id)}
                style={{
                    ...styles.Block,
                    width: buttonSize,
                    height: buttonHeight,
                    cursor: 'pointer',
                }}>
                <div style={{ ...styles.Block, backgroundColor: index == props.id ? color.white : undefined, height: buttonHeight - buttonHeightOffset, borderRadius: radius }}>
                    <p style={{ ...styles.TextSmall, color: index == props.id ? color.black : color.white, fontSize: fontSize, letterSpacing: 1 }}>{props.name}</p>
                </div>
            </div>
        )
    }

    const MenuButtonSmall = (props) => {

        const buttonHeight = 56;
        const radius = 8;
        const offset = 12;

        return (
            <div
                onClick={() => ChangeIndex(props.id)}
                style={{
                    ...styles.Block,
                    width: '100%',
                    height: buttonHeight,
                    cursor: 'pointer',
                    background: 'linear-gradient(to right,#FFFFFF12,#FFFFFF00)',
                    borderRadius: radius,
                    marginBottom: 10,
                }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: buttonHeight + 10, height: buttonHeight }}>
                        <div style={{ ...styles.Block, width: buttonHeight - offset, height: buttonHeight - offset, background: props.background, borderRadius: radius }}>
                            <FontAwesomeIcon style={{ height: 24, color: color.white }} icon={props.icon} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: undefined, height: buttonHeight }}>
                        <p style={{ ...styles.TextSmall, color: color.white, fontSize: fontSize, letterSpacing: 1 }}>{props.name}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            ref={stickyRef}
            style={{
                ...styles.BlockCenter,
                height: isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded,
                background: color.menu_color,
                position: 'sticky',
                zIndex: 100,
                top: 0,
                transition: 'height 0.50s ease',
            }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.BlockRow, width: global.max_width_padding }}>
                    {/* **************************************************************************************************
                        LOGO
                        ************************************************************************************************** */}
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '80%' : '30%', height: isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded }}>
                        <div onClick={() => ChangeIndex(0)} style={{ ...styles.Block, width: undefined, cursor: 'pointer' }}>
                            <Logo theme={props.theme} lang={lang} height={isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded} />
                        </div>
                    </div>

                    {/* **************************************************************************************************
                        BUTTONS
                    ************************************************************************************************** */}
                    <div style={{ ...styles.BlockRight, width: isSmall ? '20%' : '70%', height: isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded }}>
                        {isSmall ?
                            <div onClick={() => OpenMenu()} style={{ ...styles.Block, width: 40, cursor: 'pointer' }}>
                                <FontAwesomeIcon style={{ height: 20, color: color.white }} icon={faBars} />
                            </div>
                            :
                            <div style={{ ...styles.BlockRowRaw }}>
                                <MenuButton id={0} name={lang.home} />
                                <MenuButton id={1} name={lang.products} />
                                <MenuButton id={2} name={lang.gallery} />
                                <MenuButton id={3} name={lang.contact} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            {isSmall ?
                /*
                *
                * =================================================================================================================
                * MENU - SMALL DEVICES
                * =================================================================================================================
                *
                */
                <Dialog fullScreen open={menuOpen} style={{ zIndex: 999 }}>
                    <div style={{ ...styles.BlockCenter, height: window.innerHeight, background: `url(${background})`, backgroundSize: 'cover', overflowY: 'scroll' }}>
                        <div style={{ ...styles.Block, height: menuSmallHeight, backgroundColor: '#000000A0' }}>
                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: menuSmallHeight }}>
                                    <p style={{ ...styles.TextLarge, color: color.white }}>{lang.menu}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '70%' }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        {global.themes_enabled == true ?
                                            <div style={{ ...styles.BlockRight, width: 60, height: menuSmallHeight }}>
                                                <div onClick={() => ChangeTheme(theme == 0 ? 1 : 0)} style={{ ...styles.Block, width: iconSize, height: iconSize, background: color.button_palette, borderRadius: iconSize / 2 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white }} icon={faPalette} />
                                                </div>
                                            </div>
                                            : null}
                                        <div style={{ ...styles.Block, width: 60, height: menuSmallHeight }}>
                                            <div onClick={() => ChangeLanguage(0)} style={{ width: iconSize, height: iconSize }}>
                                                <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 0 ? 1 : disabled }}></img>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 60, height: menuSmallHeight }}>
                                            <div onClick={() => ChangeLanguage(1)} style={{ width: iconSize, height: iconSize }}>
                                                <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 1 ? 1 : disabled }}></img>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 60, height: menuSmallHeight }}>
                                            <IconButton onClick={() => CloseMenu()} style={{ ...styles.ButtonClose }}>
                                                <FontAwesomeIcon style={{ width: 12, color: color.black }} icon={faXmark} />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 30 }}>
                            <img src={logo_marvi} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, objectFit: 'contain', transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transform: menuStarted == true ? 'scale(1.00)' : 'scale(1.30)' }}></img>
                        </div>

                        <div style={{ ...styles.BlockCenter }}>
                            <div style={{ ...styles.Block, width: '90%' }}>
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0s' }}>
                                    <MenuButtonSmall id={0} name={lang.home} icon={faHome} background={'linear-gradient(135deg,#550500,#991000)'} />
                                </div>
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0.05s' }}>
                                    <MenuButtonSmall id={1} name={lang.products} icon={faP} background={'linear-gradient(135deg,#550055,#990099)'} />
                                </div>
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0.1s' }}>
                                    <MenuButtonSmall id={2} name={lang.gallery} icon={faImage} background={'linear-gradient(135deg,#002255,#006699)'} />
                                </div>
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0.15s' }}>
                                    <MenuButtonSmall id={3} name={lang.contact} icon={faPhone} background={'linear-gradient(135deg,#005555,#009999)'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                : null}
        </div>
    )

};


/*
*
* =================================================================================================================
* FOOTER
* 
* kontaktné informácie
* 
* =================================================================================================================
*
*/
export const Footer = (props) => {

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [year, setYear] = useState(GetTodayYear());

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const navigate = useNavigate();

    const padding = 40

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;
    const linkOffset = 15;

    useEffect(() => {

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);


    const ButtonLink = (props) => {
        const [selected, setSelected] = useState(false);

        return (
            <p
                onClick={() => func(0, props.id)}
                style={{
                    ...styles.TextXSmall,
                    color: selected == true ? color.light_blue : color.white,
                    cursor: 'pointer',
                    marginTop: linkOffset
                }}
                onMouseOverCapture={() => setSelected(true)} onMouseLeave={() => setSelected(false)}
            >{props.name}</p>
        );
    }

    return (
        <div elevation={1} style={{
            ...styles.BlockCenter,
            backgroundColor: color.footer_background,
            color: color.white,

        }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen, paddingTop: padding, paddingBottom: padding }}>
                <div style={{ ...styles.Block, width: global.max_width_padding }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '70%' }}>
                                <Logo white theme={props.theme} lang={lang} height={100} />
                                <p style={{ ...styles.TextXSmall, color: color.white, marginTop: 10, textAlign: 'justify' }}>{lang.footer_text}</p>
                                <p style={{ ...styles.TextXSmall, color: color.white, marginTop: 20, textAlign: 'left' }}><b>{global.company.company}</b></p>
                                <p style={{ ...styles.TextXSmall, color: color.white, marginTop: 2, textAlign: 'left' }}>{lang.mobil}: {props.company != false ? props.company.mobil : ''}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <ButtonLink id={0} name={lang.home} />
                            <ButtonLink id={1} name={lang.products} />
                            <ButtonLink id={10} name={lang.product_obklady} />
                            <ButtonLink id={11} name={lang.product_dlažby} />
                            <ButtonLink id={12} name={lang.product_muriva} />
                            <ButtonLink id={13} name={lang.badroom} />
                            <ButtonLink id={2} name={lang.gallery} />
                            <ButtonLink id={20} name={lang.about_us} />
                            <ButtonLink id={3} name={lang.contact} />
                        </div>
                    </div>

                    <div style={{ ...styles.Block, maxWidth: global.max_screen, paddingTop: padding / 2, borderTop: '1px solid #FFFFFF30', marginTop: 40 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                                <p style={{ ...styles.TextTiny, color: color.white }}>{'Datadream s.r.o.'} - {lang.copyright}: {year}</p>
                            </div>
                            <div style={{ ...isSmall ? styles.BlockLeft : styles.BlockRight, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 10 : 0 }}>
                                <p style={{ ...styles.TextTiny, color: color.white }}>{props.company != false ? props.company.email : ''}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

};


export const Loading = () => {
    /*
            USAGE:
            <Loading />
            */


    const logo = require('./react/app/logo.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 50 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_blue }} size={70} />
            </div>
        </Backdrop>
    );
}



export const BannerSlider = (props) => {

    /*
            BANNER LARGE: 2560 * 800
            BANNER MEDIUM:
            BANNER SMALL:
            */

    const speed = 2500; // dĺžka zobrazovania v ms

    const [slide, setSlide] = useState(1);
    const [slideNext, setSlideNext] = useState(0);
    const [theme, setTheme] = useState(props.theme);

    const background = require('./react/app/background.jpg');

    // ---------------------------------------------------------------------------
    // LARGE BANNERS
    // ---------------------------------------------------------------------------
    const banner_1 = require('./react/app/banner_1.jpg');
    const banner_2 = require('./react/app/banner_2.jpg');
    const banner_3 = require('./react/app/banner_3.jpg');
    const banner_4 = require('./react/app/banner_4.jpg');
    const banner_5 = require('./react/app/banner_5.jpg');
    const banner_6 = require('./react/app/banner_6.jpg');
    const banner_7 = require('./react/app/banner_7.jpg');
    const banner_8 = require('./react/app/banner_8.jpg');
    const banner_9 = require('./react/app/banner_9.jpg');

    const slides = [
        { id: 0, image_large: banner_1 },
        { id: 1, image_large: banner_2 },
        { id: 2, image_large: banner_3 },
        { id: 3, image_large: banner_4 },
        { id: 4, image_large: banner_5 },
        { id: 5, image_large: banner_6 },
        { id: 6, image_large: banner_7 },
        { id: 7, image_large: banner_8 },
        { id: 8, image_large: banner_9 },
    ];

    // THEME / COLORS
    var color = global.themes[theme];

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    var zoom = isSmall ? 0.9 : 0.35; // ZVACSENIE BANNERA - <1 = zmenšenie

    // VELKOSTI BANNERA
    const banner_size = {
        width: 1200,
        height: 680
    };

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var sliderWidth = isSmall ? width * zoom : width * zoom;
    var sliderHeight = banner_size.height * (sliderWidth / banner_size.width);
    var backgroundHeight = isSmall ? sliderHeight + 200 : isMedium ? sliderHeight + 100 : sliderHeight + 100
    var fontSize = isSmall ? 36 : isMedium ? 40 : 70;
    var bannerFontSize = isSmall ? 18 : isMedium ? 20 : 30;
    var radius = isSmall ? 40 : isMedium ? 60 : 80;

    var lang = props.lang;

    useEffect(() => {

        color = global.themes[props.theme];

        setTheme(props.theme);

        var timer = setInterval(() => {
            setSlide(prevCount => prevCount + 1 >= slides.length ? 0 : prevCount + 1);
            setSlideNext(prevCount => prevCount + 1 >= slides.length ? 0 : prevCount + 1);
        }, speed);

        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            clearInterval(timer);
            window.removeEventListener('resize', handleResize);
        };

    }, [props.theme]);

    return (
        <div style={{ ...styles.Block, height: isSmall ? backgroundHeight + 50 : backgroundHeight, backgroundColor: color.black, background: `url(${background})`, backgroundSize: 'cover', justifyContent: 'center' }}>
            <div style={{ ...styles.Block, maxWidth: global.max_banner_screen }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: global.max_width_padding }}>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 50 : 0 }}>
                        <div style={{ ...isSmall ? styles.BlockCenter : styles.BlockLeft, height: isSmall ? 150 : sliderHeight }}>
                            <p style={{ ...styles.TextXXLarge, fontSize: fontSize, color: color.lighter_blue, textAlign: 'center' }}>{lang.slogan_1}</p>
                            <p style={{ ...styles.TextXXLarge, fontSize: fontSize - 10, color: color.white, textAlign: isSmall ? 'center' : 'left' }}>{lang.slogan_2}</p>
                            <p style={{ ...styles.TextXSmall, fontSize: bannerFontSize, color: color.white, fontWeight: '300', textAlign: 'center', marginTop: isSmall ? 5 : 40 }}>{lang.slogan_banner}</p>
                        </div>
                    </div>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%' }}>
                        <div style={{ ...styles.Block, position: 'relative', overflow: 'hidden', height: sliderHeight + 50 }}>
                            <div style={{ ...styles.Block }}>
                                <img src={isSmall ? slides[slideNext].image_large : isMedium ? slides[slideNext].image_large : slides[slideNext].image_large} style={{ width: '100%', height: '100%', maxWidth: sliderWidth, objectFit: 'contain', borderTopLeftRadius: radius / 4, borderTopRightRadius: radius / 4, borderBottomLeftRadius: radius / 4, borderBottomRightRadius: radius }}></img>
                            </div>

                            {slides.map((item, index) => (
                                <div key={index} style={{
                                    ...styles.Block, position: 'absolute', scale: slide == item.id ? '1.0' : '1.1', opacity: slide == item.id ? 1 : 0, transitionDuration: slide == item.id ? '0.75s' : '0.75s'/*, transitionDelay: slide == item.id ? '0s' : String(speed / 1000) + 's'*/
                                }}>
                                    <img src={isSmall ? item.image_large : isMedium ? item.image_large : item.image_large} style={{ width: '100%', height: '100%', maxWidth: sliderWidth, objectFit: 'contain', borderTopLeftRadius: radius / 4, borderTopRightRadius: radius / 4, borderBottomLeftRadius: radius / 4, borderBottomRightRadius: radius }}></img>
                                </div>
                            ))
                            }
                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}


export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
        </div >
    );
}


export const GoHome = () => {
    var timer = setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, 250);
}

export const Line = (props) => {
    return (
        <div style={{ width: '100%', height: 1, backgroundColor: props.color != undefined ? props.color : global.theme_medium, marginTop: props.marginTop == undefined ? 0 : props.marginTop, marginBottom: props.marginBottom == undefined ? 0 : props.marginBottom }} />
    )
}

export const GetSlovom = (value) => {
    // vstup 0.0, 123.50, ....
    // vráti slovom od  0 - 999 000
    // aj centy 
    var numbers_1 = ['nula', 'jeden', 'dva', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_2 = ['nula', 'jeden', 'dve', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_3 = ['desať', 'jedennásť', 'dvanásť', 'trinásť', 'štrnásť', 'pätnásť', 'šestnásť', 'sedemnásť', 'osemnásť', 'devätnásť'];
    var numbers_4 = ['', 'desať', 'dsať', 'dsať', 'dsať', 'desiat', 'desiat', 'desiat', 'desiat', 'desiat'];

    var str_ = value.toString().replaceAll(',', '.');
    var arr = str_.split('.');

    var result = '';
    var result2 = '';
    if (arr.length > 0) {
        var str = arr[0];
        var eur = parseInt(str);

        var n = 0;

        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);
            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    result = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result = numbers_1[val] + numbers_4[val] + result;
                    }
                }
            }
            if (n == 2) {
                // stovky 100 - 900
                if (val > 0) {
                    result = numbers_2[val] + 'sto' + result;
                }
            }
            // ------------------------------------------------------------
            // 1 000 - 100 000
            // ------------------------------------------------------------
            if (n == 3) {
                // tisíce 1 - 9
                if (val > 0) {
                    result2 = numbers_2[val];
                }
            }
            if (n == 4) {
                // desaťtisíce 10 000 - 90 000
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result2 = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result2 = numbers_1[val] + numbers_4[val] + result2;
                    }
                }
            }
            if (n == 5) {
                // stotisíce 100 000 - 900 000
                if (val > 0) {
                    result2 = numbers_2[val] + 'sto' + result2;
                }
            }
            n++;
        }
    }

    if (result2 != '') {
        result2 = result2 + 'tisíc';
    }

    /* **********************************************************************
        CENTY
    ********************************************************************** */
    var centy = '';
    if (arr.length > 1) {
        var str = arr[1];

        if (str.length == 1) {
            str = str + '0';
        }
        var cent = parseInt(str);

        var n = 0;
        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);

            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    centy = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    centy = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        centy = numbers_1[val] + numbers_4[val] + centy;
                    }
                }
            }
            n++;
        }
    }
    result = result2 + result;
    if (result == '') {
        result = numbers_1[0];
    }

    var eur_text = 'eur';
    if (eur == 0) {
        eur_text = 'eur';
    }
    if (eur == 1) {
        eur_text = 'euro';
    }
    if (eur > 1 && eur < 5) {
        eur_text = 'eura';
    }
    if (eur > 4) {
        eur_text = 'eur';
    }
    result = result + ' ' + eur_text;

    if (centy != '') {
        var cent_text = 'cent';
        if (cent == 1) {
            cent_text = 'cent';
        }
        if (cent > 1 && cent < 5) {
            cent_text = 'centy';
        }
        if (cent > 4) {
            cent_text = 'centov';
        }
        result = result + ' a ' + centy + ' ' + cent_text;
    }
    return result;
}


export const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = React.useState('up');

    const blocking = React.useRef(false);
    const prevScrollY = React.useRef(0);
    const THRESHOLD = 50;

    React.useEffect(() => {
        prevScrollY.current = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
                const newScrollDirection =
                    scrollY > prevScrollY.current ? 'down' : 'up';

                setScrollDirection(newScrollDirection);

                prevScrollY.current = scrollY > 0 ? scrollY : 0;
            }

            blocking.current = false;
        };

        const onScroll = () => {
            if (!blocking.current) {
                blocking.current = true;
                window.requestAnimationFrame(updateScrollDirection);
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollDirection]);

    return scrollDirection;
};

export const Space = (props) => {
    return (
        <div style={{ ...styles.Block, minHeight: props.height == undefined ? 60 : props.height }}></div>
    )
}

/*
*
* =================================================================================================================
* <ContainerText center label={lang.stone_products} sub_text={lang.stone_products_text} theme={theme} lang={lang} language={language} />
* =================================================================================================================
*
*/

export const ContainerText = (props) => {

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    const minHeight = 300;
    var labelSize = 42;
    var textSize = 30;
    var subTextSize = 26;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 80;
    const buttonHeight = 50;

    return (
        <div
            style={{
                ...styles.Block,
                paddingBottom: 30,
                //borderBottom: '1px solid #C9DDFC',
            }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.Block, width: global.max_width_padding, alignItems: props.center == undefined ? 'flex-start' : 'center' }}>
                    {props.label != undefined ?
                        <p style={{ ...styles.TextTiny, fontSize: labelSize, fontWeight: '600', color: color.text_dark, letterSpacing: 1 }}>{props.label}</p>
                        : null}
                    {props.text != undefined ?
                        <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 10, textAlign: props.center == undefined ? 'left' : 'center' }}>{props.text}</p>
                        : null}
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, fontSize: subTextSize, color: color.text_dark, marginTop: 10, textAlign: props.center == undefined ? 'left' : 'center' }}>{props.sub_text}</p>
                        : null}
                </div>
            </div>

        </div>
    );
}


/*
*
* =================================================================================================================
* 
* =================================================================================================================
*
*/

export const ContainerContact = (props) => {

    const logo = require('./react/app/logo_stones.jpg');

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    const imageSize = 360;
    var labelSize = 28;
    var textSize = 24;
    var subTextSize = 20;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 80;
    const buttonHeight = 50;

    return (
        <div
            style={{
                ...styles.Block,
                paddingBottom: 30,
            }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.Block, width: global.max_width_padding, alignItems: props.center == undefined ? 'flex-start' : 'center' }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                        <div style={{ ...styles.BlockLeft, width: imageSize, maxHeight: imageSize }}>
                            <img src={logo} style={{ width: '100%', height: '100%', maxHeight: imageSize, maxWidth: imageSize, objectFit: 'contain', borderRadius: 20 }}></img>
                        </div>
                        <div style={{ ...styles.Block, width: 400, height: imageSize }}>
                            <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                <p style={{ ...styles.TextTiny, fontSize: labelSize, fontWeight: '600', color: color.text_dark, letterSpacing: 1 }}>{props.company.company != false ? props.company.company : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 20 }}>{props.company.street != false ? props.company.street : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 2 }}>{props.company.psc} {props.company.town != false ? props.company.town : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 2 }}>{props.company.country != false ? props.company.country : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 20 }}>{lang.ico}: {props.company.ico != false ? props.company.ico : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 2 }}>{lang.dic}: {props.company.dic != false ? props.company.dic : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 20 }}>{lang.mobil.toLowerCase()}: {props.company.mobil != false ? props.company.mobil : ''}</p>
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 2 }}>{lang.email.toLowerCase()}: {props.company.email != false ? props.company.email : ''}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


/*
*
* =================================================================================================================
* <ContainerBasic background={'#F5F8FF'} noBorder noButton label={'O Nás'} text={'Lorem ipsum '} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
* =================================================================================================================
*
*/

export const ContainerBasic = (props) => {

    const background_white = require('./react/app/background_white.jpg');

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    const minHeight = 300;
    var labelSize = 42;
    var textSize = 26;
    var iconSize = 50;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 80;
    const buttonHeight = 50;

    let { func } = props;

    return (
        <div
            style={{
                ...styles.Block,
                background: props.background == undefined ? `url(${background_white})` : undefined,
                backgroundColor: props.background == undefined ? undefined : props.background,
                backgroundSize: 'cover',
                minHeight: minHeight,
                borderRadius: radius / 4 + 'px ' + radius / 4 + 'px ' + radius + 'px ' + radius / 4 + 'px ',
                border: props.noBorder == undefined ? '1px solid #C9DDFC' : undefined,
            }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: global.max_width_padding }}>
                <div style={{ ...styles.BlockLeft, width: isSmall ? '96%' : '60%', paddingTop: 20 }}>
                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                        <div style={{ ...styles.Block, width: iconSize, height: iconSize, borderRadius: 10, backgroundColor: color.blue }}>
                            <FontAwesomeIcon style={{ height: 26, color: color.white }} icon={props.icon} />
                        </div>
                        <p style={{ ...styles.TextTiny, fontSize: labelSize, fontWeight: '600', color: color.text_dark, letterSpacing: 1, marginLeft: 15 }}>{props.label}</p>
                    </div>

                    <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 30 }}>{props.text}</p>

                    {props.noButton != true ?
                        <Paper elevation={3} onClick={() => func(true)} style={{ ...styles.Block, width: undefined, minHeight: buttonHeight, borderRadius: 10, marginTop: 20, backgroundColor: '#FFFFFF4', border: '1px solid #E0E0E0', cursor: 'pointer' }}>
                            <div style={{ ...styles.BlockRowRaw, marginLeft: 20, marginRight: 20 }}>
                                <div style={{ ...styles.Block, width: 30, height: buttonHeight }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faPlay} />
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: buttonHeight }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>Viac informácii</p>
                                </div>
                            </div>
                        </Paper>
                        : null}

                </div>
                <div style={{ ...styles.BlockRight, width: isSmall ? '96%' : '40%' }}>
                    <div style={{ ...styles.Block, maxWidth: 500, paddingTop: 30, paddingBottom: 30 }}>
                        <img src={props.image} style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: radius / 6 + 'px ' + radius / 6 + 'px ' + radius / 1.5 + 'px ' + radius / 6 + 'px ' }}></img>
                    </div>
                </div>
            </div>

        </div>
    );
}

export const ContainerBasic2 = (props) => {

    const background_white = require('./react/app/background_white.jpg');

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    const minHeight = 400;
    var labelSize = 42;
    var textSize = 26;
    var subTextSize = 24;
    var iconSize = 50;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 80;
    const buttonHeight = 50;

    let { func } = props;

    return (
        <div
            style={{
                ...styles.Block,
                background: props.background == undefined ? `url(${background_white})` : undefined,
                backgroundColor: props.background == undefined ? undefined : props.background,
                backgroundSize: 'cover',
                minHeight: minHeight,
                borderRadius: radius / 4 + 'px ' + radius / 4 + 'px ' + radius + 'px ' + radius / 4 + 'px ',
                border: props.noBorder == undefined ? '1px solid #C9DDFC' : undefined,
            }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: global.max_width_padding }}>

                <div style={{ ...styles.BlockRight, width: isSmall ? '96%' : '50%' }}>
                    <div style={{ ...styles.Block, maxWidth: 700, paddingTop: 30, paddingBottom: 30 }}>
                        <img src={props.image} style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: radius / 6 + 'px ' + radius / 6 + 'px ' + radius / 1.5 + 'px ' + radius / 6 + 'px ' }}></img>
                    </div>
                </div>

                <div style={{ ...styles.Block, width: isSmall ? '96%' : '50%', paddingTop: isSmall ? 0 : 20, paddingBottom: isSmall ? 40 : 0 }}>
                    <div style={{ ...styles.BlockLeft, width: '80%' }}>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                            <div style={{ ...styles.Block, width: iconSize, height: iconSize, borderRadius: 10, backgroundColor: color.blue }}>
                                <FontAwesomeIcon style={{ height: 26, color: color.white }} icon={props.icon} />
                            </div>
                            <p style={{ ...styles.TextTiny, fontSize: labelSize, fontWeight: '600', color: color.text_dark, letterSpacing: 1, marginLeft: 15 }}>{props.label}</p>
                        </div>

                        <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 30 }}>{props.text}</p>

                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, fontSize: subTextSize, color: color.text_dark, marginTop: isMedium ? 20 : 30 }}>{props.sub_text}</p>
                            : null}

                        {props.noButton != true ?
                            <Paper elevation={3} onClick={() => func(true)} style={{ ...styles.Block, width: undefined, minHeight: buttonHeight, borderRadius: 10, marginTop: 20, backgroundColor: '#FFFFFF4', border: '1px solid #E0E0E0', cursor: 'pointer' }}>
                                <div style={{ ...styles.BlockRowRaw, marginLeft: 20, marginRight: 20 }}>
                                    <div style={{ ...styles.Block, width: 30, height: buttonHeight }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faPlay} />
                                    </div>
                                    <div style={{ ...styles.Block, width: undefined, height: buttonHeight }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>Viac informácii</p>
                                    </div>
                                </div>
                            </Paper>
                            : null}
                    </div>
                </div>

            </div>

        </div>
    );
}



export const ContainerLight = (props) => {

    const background_white = require('./react/app/background_light.jpg');

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    const minHeight = 600;
    var labelSize = 42;
    var textSize = 30;
    var iconSize = 50;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 80;
    const buttonHeight = 50;

    let { func } = props;

    return (
        <div
            style={{
                ...styles.Block,
                background: props.background == undefined ? `url(${background_white})` : undefined,
                backgroundColor: props.background == undefined ? undefined : props.background,
                backgroundSize: 'cover',
                minHeight: minHeight,
                borderRadius: radius / 4 + 'px ' + radius / 4 + 'px ' + radius + 'px ' + radius / 4 + 'px ',
                border: props.noBorder == undefined ? '1px solid #C9DDFC' : undefined,
            }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: global.max_width_padding }}>
                <div style={{ ...styles.BlockLeft, width: isSmall ? '96%' : '60%', paddingTop: 20 }}>
                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                        <div style={{ ...styles.Block, width: iconSize, height: iconSize, borderRadius: 10, backgroundColor: color.blue }}>
                            <FontAwesomeIcon style={{ height: 26, color: color.white }} icon={props.icon} />
                        </div>
                        <p style={{ ...styles.TextTiny, fontSize: labelSize, fontWeight: '600', color: color.text_dark, letterSpacing: 1, marginLeft: 15 }}>{props.label}</p>
                    </div>

                    <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, marginTop: 30 }}>{props.text}</p>

                    {props.noButton != true ?
                        <Paper elevation={3} style={{ ...styles.Block, width: undefined, minHeight: buttonHeight, borderRadius: 10, marginTop: 20, backgroundColor: '#FFFFFF4', border: '1px solid #E0E0E0' }}>
                            <div style={{ ...styles.BlockRowRaw, marginLeft: 20, marginRight: 20 }}>
                                <div style={{ ...styles.Block, width: 30, height: buttonHeight }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faPlay} />
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: buttonHeight }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>Viac informácii</p>
                                </div>
                            </div>
                        </Paper>
                        : null}
                </div>
                <div style={{ ...styles.BlockRight, width: isSmall ? '96%' : '40%', paddingTop: isSmall ? 40 : 0, paddingBottom: isSmall ? 40 : 0 }}>
                    <div style={{ ...styles.Block, maxHeight: minHeight - 80, maxWidth: minHeight - 80 }}>
                        <img src={props.image} style={{ width: '100%', height: '100%', maxHeight: minHeight - 80, objectFit: 'contain', borderRadius: radius / 6 + 'px ' + radius / 6 + 'px ' + radius / 1.5 + 'px ' + radius / 6 + 'px ' }}></img>
                    </div>
                </div>
            </div>

        </div>
    );
}



export const ContainerDark = (props) => {

    const background = require('./react/app/background_dark.jpg');

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    const minHeight = 500;
    var labelSize = 42;
    var textSize = 30;
    var iconSize = 50;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 80;
    const buttonHeight = 50;

    let { func } = props;

    return (
        <div
            style={{
                ...styles.Block,
                background: `url(${background})`,
                backgroundSize: 'cover',
                minHeight: minHeight,
            }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                <div style={{ ...styles.Block, width: isSmall ? '96%' : '50%', minHeight: isLarge ? minHeight : undefined }}>
                    <div style={{ ...styles.BlockLeft, width: isLarge ? '90%' : '96%', marginTop: isSmall || isMedium ? 28 : 0, marginLeft: 20 }}>
                        {props.icon != undefined ?
                            <div style={{ ...styles.Block, width: iconSize, height: iconSize, borderRadius: 10, backgroundColor: color.blue, marginBottom: 20 }}>
                                <FontAwesomeIcon style={{ height: 26, color: color.white }} icon={props.icon} />
                            </div>
                            : null}

                        <p style={{ ...styles.TextTiny, fontSize: labelSize, fontWeight: '600', color: color.white, letterSpacing: 1 }}>{props.label}</p>
                        <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.white, marginTop: 10 }}>{props.text}</p>

                        <Paper elevation={3} onClick={() => func(true)} style={{ ...styles.Block, width: undefined, minHeight: buttonHeight, borderRadius: 10, marginTop: 50, backgroundColor: '#FFFFFF4', border: '1px solid #E0E0E0', cursor: 'pointer' }}>
                            <div style={{ ...styles.BlockRowRaw, marginLeft: 20, marginRight: 20 }}>
                                <div style={{ ...styles.Block, width: 30, height: buttonHeight }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faPlay} />
                                </div>
                                <div style={{ ...styles.Block, width: undefined, height: buttonHeight }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>Viac informácii</p>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
                <div style={{ ...styles.BlockRight, width: isSmall ? '96%' : '50%', minHeight: isSmall ? undefined : minHeight, justifyContent: 'flex-end', paddingTop: isMedium ? 0 : 50 }}>
                    <div style={{ ...styles.Block }}>
                        <img src={props.image} style={{ width: '100%', height: '100%', objectFit: 'contain', borderTopLeftRadius: radius / 1.5 }}></img>
                    </div>
                </div>
            </div>

        </div>
    );
}


export const ContainerContacForm = (props) => {

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];


    const background = require('./react/app/background_dark.jpg');

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [okText, setOKText] = useState('');

    const [name, setName] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [recaptcha, setRecaptcha] = useState('');
    const [width, setWidth] = useState(window.innerWidth);

    const maxWidth = width > 400 ? 400 : width - 20;
    var labelSize = 42;
    var textSize = 22;
    var iconSize = 50;

    const radius = 80;
    const buttonHeight = 50;

    let { func } = props;

    /* *******************************************************************************
        POSLAT SPRAVU
    ******************************************************************************* */
    const db_send = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'message_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    mobil: mobil,
                    email: email,
                    message: message,
                })
            })

            const json = await response.json();

            Debug(json);

            if (json.ok > 0) {
                // SPRAVA USPESNE ODOSLANA
                setName('');
                setMobil('');
                setEmail('');
                setMessage('');
                setOKText(lang.message_send_ok);

            } else {
                // NASTALA CHYBA
                setError(lang.db_error);

            }

            setBusy(false);
        } catch (error) {
            setBusy(false);
            setError(lang.db_error);
        }
    }

    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    const Send = () => {
        var err = 0;
        setError('');

        err = IsEmpty(name) == true ? 1 : 0;
        err = IsEmpty(email) == true ? 1 : 0;
        err = IsEmpty(message) == true ? 1 : 0;

        if (err > 0) {
            setError(lang.required);
        } else {
            if (recaptcha == '') {
                setError(lang.captcha_error);
            } else {
                // ODOSLANIE SPRAVY
                db_send();
            }
        }
    }

    return (
        <div
            style={{
                ...styles.Block,
                background: `url(${background})`,
                backgroundSize: 'cover',
                paddingTop: 20, paddingBottom: 40
            }}>
            <div style={{ ...styles.Block }}>
                <div style={{ ...styles.Block }}>
                    <p style={{ ...styles.TextTiny, color: color.white, fontSize: labelSize }}>{lang.contact_form}</p>
                    <p style={{ ...styles.TextTiny, color: color.white, fontSize: textSize, textAlign: 'center' }}>{lang.contact_form_text}</p>
                </div>
                <Paper style={{ ...styles.Block, maxWidth: maxWidth, marginTop: 30, paddingTop: 20, paddingBottom: 20, borderRadius: 20 }} >
                    <div style={{ ...styles.Block, width: '90%' }}>
                        {/* MENO */}
                        <div style={{ ...styles.Block }}>
                            <TextInput required redraw enabled={true} lang={lang} value={name} label={lang.name_surname} func={(txt) => setName(txt)} />
                        </div>

                        {/* MOBIL */}
                        <div style={{ ...styles.Block, marginTop: 10 }}>
                            <TextInput redraw enabled={true} lang={lang} value={mobil} label={lang.message_mobil} func={(txt) => setMobil(txt)} />
                        </div>

                        {/* EMAIL */}
                        <div style={{ ...styles.Block, marginTop: 10 }}>
                            <TextInput required redraw enabled={true} lang={lang} value={email} label={lang.message_email} func={(txt) => setEmail(txt)} />
                        </div>

                        {/* TEXT */}
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <TextInput rows={6} redraw enabled={true} lang={lang} value={message} label={lang.message_text} func={(txt) => setMessage(txt)} />
                        </div>

                        <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                            <ReCAPTCHA
                                sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                onChange={onChangeCaptcha}
                            />

                            <div style={{ ...styles.Block, marginBottom: 20 }}>
                                <FormError theme={props.theme} error={error != '' ? error : okText != '' ? okText : ''} small backgroundColor={error != '' ? color.dark_red : color.dark_green} />
                            </div>

                            {/* ODOSLAT */}
                            <div style={{ ...styles.Block, width: '95%', marginTop: 10, marginBottom: 20 }}>

                                {isBusy == false ?
                                    <Button onClick={() => { Send() }} style={{ ...styles.ButtonThemed, backgroundColor: color.dark }}>{lang.send}</Button>
                                    :
                                    <div style={{ ...styles.Block, height: 34 }}>
                                        <CircularProgress style={{ width: 30, height: 30 }} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </Paper>
            </div>

        </div>
    );
}



export const ContainerBox = (props) => {

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [selected, setSelected] = useState(false);

    var labelSize = 24;
    var textSize = 17;
    var buttonSize = 17;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 18;
    const boxWidth = props.small == true ? 270 : 360;
    const boxHeight = props.small == true ? 320 : props.noButton != true ? 450 : 380;
    const imageHeight = props.small == true ? 180 : 240;
    const buttonHeight = props.noButton != true ? 90 : 0;

    let { func } = props;

    const Press = () => {

    }

    return (
        <Paper
            elevation={selected == true ? 6 : 1}
            style={{
                ...styles.BlockCenter,
                width: boxWidth,
                height: boxHeight,
                backgroundColor: color.white,
                borderRadius: radius,
                margin: 10,
            }}
            onMouseOverCapture={() => setSelected(true)} onMouseLeave={() => setSelected(false)}
        >
            <div style={{ ...styles.BlockCenter, height: boxHeight - buttonHeight }}>
                <div style={{ ...styles.BlockCenter, height: imageHeight }}>
                    <img src={props.image} style={{ width: '100%', height: '100%', maxWidth: boxWidth, objectFit: 'contain', borderTopLeftRadius: radius, borderTopRightRadius: radius }}></img>
                </div>
                <div style={{ ...styles.BlockCenter, width: '96%', height: boxHeight - imageHeight - buttonHeight }}>
                    <p style={{ ...styles.TextTiny, marginTop: 10, fontSize: labelSize, fontWeight: '600', color: color.text_dark, letterSpacing: 1 }}>{props.label}</p>
                    {props.small != true ?
                        <div style={{ marginTop: 5, display: 'block', lineHeight: 1.5, maxHeight: '4.5em', alignContent: 'flex-start', maxWidth: '98%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span style={{
                                ...styles.TextXSmall,
                                textAlign: 'left',
                                fontSize: textSize
                            }}>
                                {props.text}
                            </span>
                        </div>
                        : null}
                </div>
            </div>
            {props.noButton != true ?
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div onClick={() => func(true)} style={{ ...styles.Block, height: buttonHeight - 40, width: boxWidth - 40, backgroundColor: '#3D57DA', borderRadius: 15, cursor: 'pointer' }}>
                        <p style={{ ...styles.TextSmall, color: color.white, fontSize: buttonSize, letterSpacing: 1, fontWeight: '600' }}>{props.button}</p>
                    </div>
                </div>
                : null}
        </Paper>
    );
}


export const ContainerPhoto = (props) => {

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [selected, setSelected] = useState(false);

    var labelSize = 24;
    var textSize = 17;
    var buttonSize = 17;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 18;
    const imageSize = isSmall ? 186 : 240;
    const margin = isSmall ? 5 : 10;

    let { func } = props;

    return (
        <Paper
            onClick={() => func(props.item)}
            elevation={selected == true ? 6 : 1}
            style={{
                ...styles.BlockCenter,
                width: imageSize,
                height: imageSize,
                backgroundColor: color.white,
                borderRadius: radius,
                overflow: 'hidden',
                margin: margin,
            }}
            onMouseOverCapture={() => setSelected(true)} onMouseLeave={() => setSelected(false)}
        >
            <div style={{ ...styles.BlockCenter, height: imageSize }}>
                <div style={{ ...styles.BlockCenter, height: imageSize }}>
                    <img src={props.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: radius, transition: 'transform 0.3s ease-in-out', transform: selected ? 'scale(1.15)' : 'scale(1.0)' }}></img>
                </div>
            </div>
        </Paper>
    );
}



export const ContainerCompare = (props) => {

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    var labelSize = 50;
    var labelSmallSize = 40;
    var textSize = 26;
    var subLabelSize = 22;
    var subTextSize = 20;

    // LANGUAGE
    var lang = props.lang;

    // THEME / COLORS
    var color = global.themes[props.theme];

    const radius = 20;
    const boxWidth = 326;
    const boxHeight = 220;
    const iconSize = 40;
    const boxOffset = 40;

    let { func } = props;

    const BoxContainer = (props) => {
        return (

            <div style={{ ...styles.Block, width: boxWidth, height: boxHeight, backgroundColor: '#F5F8FF', borderRadius: radius, margin: 5 }}>
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.BlockRow, height: iconSize + boxOffset / 2 }}>
                        <div style={{ ...styles.BlockRight, width: iconSize + boxOffset / 2, height: iconSize + boxOffset / 2 }}>
                            <div style={{ ...styles.Block, width: iconSize, height: iconSize, backgroundColor: props.icon_color, borderRadius: radius / 2 }}>
                                <FontAwesomeIcon style={{ height: 26, color: color.white }} icon={props.icon} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: boxWidth - iconSize - boxOffset, height: iconSize + boxOffset / 2 }}>
                            <p style={{ ...styles.TextSmall, fontSize: subLabelSize, fontWeight: '600', marginLeft: 10 }}>{props.label}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: boxWidth - boxOffset, height: boxHeight - iconSize - boxOffset }}>
                        <p style={{ ...styles.TextSmall, color: color.text_dark, fontSize: subTextSize, textAlign: 'left' }}>{props.text}</p>
                    </div>
                </div>
            </div>

        );
    }

    return (
        <div
            style={{
                ...styles.Block,
                paddingBottom: 30,
                //borderBottom: '1px solid #C9DDFC',
            }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.Block, width: global.max_width_padding, alignItems: props.center == undefined ? 'flex-start' : 'center' }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                            {props.label != undefined ?
                                <p style={{ ...styles.TextTiny, fontSize: isSmall ? labelSmallSize : labelSize, textAlign: 'left', fontWeight: '600', color: color.text_dark, letterSpacing: 1 }}>{props.label}</p>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            {props.text != undefined ?
                                <p style={{ ...styles.TextTiny, fontSize: textSize, color: color.text_dark, textAlign: 'left' }}>{props.text}</p>
                                : null}
                        </div>
                    </div>

                    <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap', marginTop: 40 }}>
                        <BoxContainer icon={faCheckCircle} icon_color={color.light_blue} label={lang.stone_advantage_1} text={lang.stone_advantage_text_1} />
                        <BoxContainer icon={faCheckCircle} icon_color={color.light_violet} label={lang.stone_advantage_2} text={lang.stone_advantage_text_2} />
                        <BoxContainer icon={faCheckCircle} icon_color={color.light_cyan} label={lang.stone_advantage_3} text={lang.stone_advantage_text_3} />
                        <BoxContainer icon={faCheckCircle} icon_color={color.light_green} label={lang.stone_advantage_4} text={lang.stone_advantage_text_4} />
                    </div>

                </div>
            </div>

        </div>
    );
}


export const TextInput = (props) => {
    /* USAGE        
        
        const [userData, setUserData] = useState({mobil: ''});

        <TextInput required redraw enabled={true} lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />

        required    -> text je vyzadovany
        not_zero    -> nesie byt nulovy
        time        -> vklad casu
        outline     -> ak je chyba, zobrazí sa cervený ramik bez textu
        phone       -> jedna sa o tel. cislo v medzinárodnom formate
        email       -> kontroluje správnost e-mailovej adresy
        enabled     -> editovanie true / false
        label       -> place holder
        redraw      -> text bude prekreslovaný pri zmene iných textových polí - setRedraw(!redraw)
        type        -> default = 'text', 'date'
        placeholder -> placeholder text
        money       -> zobrazi menu na konci textboxu
        money_text  -> text na konci textboxu (undefined == money)
        width       -> širka boxu
        center      -> vycentrovať titulok aj box
        numeric     -> zadávanie len číslic [0-9]
        rows        -> pocet riadkov
    */


    const [text, setText] = useState(props.value);
    const [error, setError] = useState('');

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        Check(props.value);
        if (props.redraw != undefined) {
            setText(props.value);
        }

        return () => {
        };

    }, [props.value]);

    const ChangeText = (txt) => {
        if (props.numeric == true) {
            if (/^[0123456789:,.]+$/.test(txt)) {
                setText(txt);
                setText(txt);
                Check(txt);
                func(txt);
            }
            if (txt == '') {
                setText(txt);
                setText(txt);
                Check(txt);
                func(txt);
            }
        } else {
            setText(txt);
            Check(txt);
            func(txt);
        }
    }

    const Check = (text) => {
        if (error != '') {
            setError('');
        }
        if (text != undefined) {
            if (props.phone) {
                if (text.trim() != '') {
                    if (text[0] != '+') {
                        setError(lang.format_error);
                    }
                }
            }

            if (props.numeric) {
                if (props.not_zero) {
                    if (text.trim() != '') {
                        if (parseInt(text) == 0) {
                            setError(lang.non_zero_error);
                        }
                    }
                }
            }
        }
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block }}>
            <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, marginLeft: 12, textAlign: 'left' }}>{props.label}</p>

            <TextField
                inputRef={props.inputRef}
                value={text}
                //onBlur={() => Check(text)}
                onInput={e => ChangeText(e.target.value)}
                size="small"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                style={{ width: props.width == undefined ? '100%' : props.width }}
                placeholder={props.placeholder == undefined ? '' : props.placeholder}
                multiline={props.rows != undefined ? true : false}
                rows={props.rows != undefined ? props.rows : 1}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                    }
                }}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                    },
                    fieldset: { borderLeft: props.required == true ? '3px solid red' : undefined }
                }}
                InputProps={{
                    endAdornment: props.money == true ? <InputAdornment position="end">
                        <p style={{ ...styles.TextSmall }}>{props.money_text == undefined ? lang.money : props.money_text}</p>
                    </InputAdornment> : null,
                    style: { backgroundColor: props.enabled == false ? '#00000007' : global.theme_white, borderRadius: 20, color: global.theme_black },
                }}
                type={props.date == true ? 'date' : props.password == true ? 'password' : props.time == true ? 'time' : props.type != undefined ? props.type : 'text'}
                variant="outlined"
                disabled={props.enabled == false ? true : false}
            />
        </div>
    );
}


export const FormError = (props) => {
    /*

            <FormError theme={props.theme} error={error} small />
            -----------------------------------------------------
        small   -> menšia verzia
        margin  -> marginfrom top
            */
    const height = 40;
    let color = global.themes[props.theme];

    return (
        <div style={{ ...styles.Block, width: props.small == true ? undefined : '100%', minHeight: height, backgroundColor: props.backgroundColor == undefined ? color.dark_red : props.backgroundColor, borderRadius: 20, marginTop: props.margin == undefined ? 20 : props.margin }}>
            {props.error != '' ?
                <div style={{ ...styles.BlockRowRaw }}>
                    {props.isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: color.white, marginLeft: 15 }} icon={faWarning} />
                    }
                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextXSmall, color: color.white, marginLeft: 10, marginRight: 15 }}>{props.error}</p>
                </div>
                : null}
        </div>
    );
}

export const GetPages = (count, itemsMax) => {
    // PAGIMATION - výpočet počtu strán
    return Math.ceil(count / itemsMax);
}

