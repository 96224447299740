import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { sk } from './language_sk.js';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerLight, ContainerCompare, Line, GoHome, ContainerBasic2 } from './items.js';
import { faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';


export const AboutUs = (props) => {

    // IMAGES
    const image = require('./react/app/about_us_3.jpg');
    const image_contact = require('./react/app/contact_us_2.jpg');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState('');
    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 99); // ID STRANKY -> prepnutie menu (99 - none)
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------


        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);


    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: color.back_blue }}>


            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.back_blue }}>
                <Space />
                <ContainerBasic2 noButton noBorder background={color.white} icon={faInfoCircle} label={lang.about_us} text={lang.about_us_label} sub_text={lang.about_us_sub_label} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 99)} />
                <Space />
                <ContainerText sub_text={lang.about_us_text_1} theme={theme} lang={lang} language={language} />
                <ContainerText sub_text={lang.about_us_text_2} theme={theme} lang={lang} language={language} />
                <ContainerText sub_text={lang.about_us_text_3} theme={theme} lang={lang} language={language} />
                <Space />
            </div>

            <ContainerDark icon={faPhone} label={lang.contact_us} text={lang.contact_us_text} theme={theme} lang={lang} language={language} image={image_contact} func={() => func(1, 3)} />

        </div>
    )
}
