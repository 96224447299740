import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerLight, ContainerCompare, Line, GoHome } from './items.js';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';


export const ProductsKupelne = (props) => {

    // IMAGES
    const image = require('./react/app/kupelna.jpg');
    const image_gallery = require('./react/app/gallery_6.jpg');
    const image1 = require('./react/app/kupelna_1.jpg');
    const image2 = require('./react/app/kupelna_2.jpg');
    const image3 = require('./react/app/kupelna_3.jpg');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState('');
    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 99); // ID STRANKY -> prepnutie menu
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------


        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);


    return (
        <div style={{ ...styles.BlockCenter }}>


            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.white }}>
                <Space />
                <ContainerLight noButton icon={faGripVertical} label={lang.badroom} text={lang.bathroom_intro_text} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 10)} />
                <Space />
                <ContainerText center sub_text={lang.bathroom_intro_sub_text} theme={theme} lang={lang} language={language} />
                <Space />
                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                    <ContainerBox noButton label={lang.bathroom_variant_1} text={lang.bathroom_variant_1_text} button={lang.watch} theme={theme} lang={lang} language={language} image={image1} func={() => func(1, 99)} />
                    <ContainerBox noButton label={lang.bathroom_variant_2} text={lang.bathroom_variant_2_text} button={lang.watch} theme={theme} lang={lang} language={language} image={image2} func={() => func(1, 99)} />
                    <ContainerBox noButton label={lang.bathroom_variant_3} text={lang.bathroom_variant_3_text} button={lang.watch} theme={theme} lang={lang} language={language} image={image3} func={() => func(1, 99)} />
                </div>
                <Space height={90} />
                <ContainerText center sub_text={lang.bathroom_variant_footer_text} theme={theme} lang={lang} language={language} />
                <Space />
            </div>

            <ContainerDark label={lang.stone_gallery} text={lang.stone_gallery_note} theme={theme} lang={lang} language={language} image={image_gallery} func={() => func(1, 2)} />

        </div>
    )
}

/*
<ContainerLight  background={'#F5F8FF'} noBorder  label={'O Nás'} text={'Informácie o spoločnosti Marvi Stoner'} theme={theme} lang={lang} language={language} icon={faInfoCircle} image={image_square} />
<div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
    <ContainerBox label={'O Nás'} text={'Lorem ipsum dolor sit amet,aa s s d  fconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.'} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
    <ContainerBox label={'O Nás'} text={'Lorem ipsum dolor sit amet,aa s s d  fconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.'} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
    <ContainerBox label={'O Nás'} text={'Lorem ipsum dolor sit amet,aa s s d  fconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.'} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
</div>
*/